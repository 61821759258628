import { Checkbox } from "@nextui-org/checkbox"
import { Radio, RadioGroup } from "@nextui-org/radio"
import { FC } from "react"

import { TRaidStatus } from "@/api/raid"

export type StatusFilter = TRaidStatus | "すべて"
export type FormatFilter = "オンライン" | "対面" | "両方"
export type PeriodFilter = "1~6日" | "1~4週間" | "1ヶ月~" | "すべて"

type Props = {
  formatFilter: FormatFilter | string
  setFormatFilter: (value: string) => void
  areaOptions: string[]
  areaFilter: string[]
  setAreaFilter: (value: string[]) => void
  periodFilter: PeriodFilter | string
  setPeriodFilter: (value: string) => void
  tagOptions: string[]
  tagFilter: string[]
  setTagFilter: (value: string[]) => void
}

export const FilterContent: FC<Props> = ({
  formatFilter,
  setFormatFilter,
  areaOptions,
  areaFilter,
  setAreaFilter,
  periodFilter,
  setPeriodFilter,
  tagOptions,
  tagFilter,
  setTagFilter,
}) => (
  <div className="space-y-5">
    <div>
      <h4 className="font-semibold">形式</h4>
      <RadioGroup
        value={formatFilter}
        onValueChange={(value: string) => setFormatFilter(value)}
      >
        <Radio value="両方">両方</Radio>
        <Radio value="オンライン">オンライン</Radio>
        <Radio value="対面">対面</Radio>
      </RadioGroup>
    </div>
    <div>
      <h4 className="font-semibold">開催地域</h4>
      <div className="flex flex-col gap-1">
        {areaOptions.map((area) => (
          <Checkbox
            key={area}
            isSelected={areaFilter.includes(area)}
            onValueChange={(isSelected) => {
              if (isSelected) {
                setAreaFilter([...areaFilter, area])
              } else {
                setAreaFilter(areaFilter.filter((a) => a !== area))
              }
            }}
          >
            {area}
          </Checkbox>
        ))}
      </div>
    </div>
    <div>
      <h4 className="font-semibold">期間</h4>
      <RadioGroup
        value={periodFilter}
        onValueChange={(value: string) => setPeriodFilter(value)}
      >
        <Radio value="すべて">すべて</Radio>
        <Radio value="1~6日">1~6日</Radio>
        <Radio value="1~4週間">1~4週間</Radio>
        <Radio value="1ヶ月~">1ヶ月~</Radio>
      </RadioGroup>
    </div>
    <div>
      <h4 className="font-semibold">タグ</h4>
      <div className="flex flex-col gap-1">
        {tagOptions.map((tag) => (
          <Checkbox
            key={tag}
            isSelected={tagFilter.includes(tag)}
            onValueChange={(isSelected) => {
              if (isSelected) {
                setTagFilter([...tagFilter, tag])
              } else {
                setTagFilter(tagFilter.filter((t) => t !== tag))
              }
            }}
          >
            {tag}
          </Checkbox>
        ))}
      </div>
    </div>
  </div>
)
