type Props = {
  value: string
}

export const HackathonTag: React.FC<Props> = ({ value }: Props) => {
  return (
    <div className="flex h-6 items-center rounded-3xl bg-[#E9D7FE]">
      <span className="px-3 text-xs font-bold text-black">{value}</span>
    </div>
  )
}
