"use client"

import { Button } from "@nextui-org/button"
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/modal"
import { useMemo, useState } from "react"
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri"

import {
  FilterContent,
  FormatFilter,
  PeriodFilter,
  StatusFilter,
} from "./FilterContent"
import { HackathonCard } from "./HackathonCard"

import { TOrganizer } from "@/api/organizer"
import { TRaidSummary } from "@/api/raid"

type Props = {
  hackathonList: TRaidSummary[]
  hackathonOrganizerMap: Map<string, TOrganizer[]>
  areaOptions: string[]
  tagOptions: string[]
}

const hackathonSortFunc = (a: TRaidSummary, b: TRaidSummary): number => {
  if (a.info.status === "開催中" && b.info.status !== "開催中") return -1
  if (a.info.status !== "開催中" && b.info.status === "開催中") return 1
  if (a.info.status === "開催予定" && b.info.status === "終了") return -1
  if (a.info.status === "終了" && b.info.status === "開催予定") return 1
  return a.info.eventDate.raidStart - b.info.eventDate.raidStart
}

export const HackathonList: React.FC<Props> = ({
  hackathonList,
  hackathonOrganizerMap,
  areaOptions,
  tagOptions,
}: Props) => {
  const [statusFilter, setStatusFilter] = useState<StatusFilter>("すべて")
  const [formatFilter, setFormatFilter] = useState<FormatFilter | string>(
    "両方",
  )
  const [areaFilter, setAreaFilter] = useState<string[]>([])
  const [periodFilter, setPeriodFilter] = useState<PeriodFilter | string>(
    "すべて",
  )
  const [tagFilter, setTagFilter] = useState<string[]>([])

  // SPモーダル用のstate
  const [isSPFilterModalOpen, setIsSPFilterModalOpen] = useState(false)

  const clearFilters = () => {
    setStatusFilter("すべて")
    setFormatFilter("両方")
    setAreaFilter([])
    setPeriodFilter("すべて")
    setTagFilter([])
    setIsSPFilterModalOpen(false)
  }

  const matchesPeriodFilter = (
    hackathon: TRaidSummary,
    filter: PeriodFilter | string,
  ) => {
    const durationInDays =
      (hackathon.info.eventDate.raidEnd - hackathon.info.eventDate.raidStart) /
      (24 * 60 * 60)
    switch (filter) {
      case "1~6日":
        return durationInDays <= 6
      case "1~4週間":
        return durationInDays > 6 && durationInDays <= 28
      case "1ヶ月~":
        return durationInDays > 28
      default:
        return true
    }
  }

  const filteredAndSortedHackathonList = useMemo(() => {
    return hackathonList
      .filter((hackathon) => {
        const matchesStatus =
          statusFilter === "すべて" || hackathon.info.status === statusFilter
        const matchesFormat =
          formatFilter === "両方" ||
          (formatFilter === "オンライン" &&
            hackathon.info.venueInfo.isOnline) ||
          (formatFilter === "対面" && !hackathon.info.venueInfo.isOnline)
        const matchesArea =
          areaFilter.length === 0 ||
          areaFilter.includes(hackathon.info.venueInfo.venueArea)
        const matchesPeriod =
          periodFilter === "すべて" ||
          matchesPeriodFilter(hackathon, periodFilter)
        const matchesTags =
          tagFilter.length === 0 ||
          hackathon.tags.some((tag) => tagFilter.includes(tag.value))

        return (
          matchesStatus &&
          matchesFormat &&
          matchesArea &&
          matchesPeriod &&
          matchesTags
        )
      })
      .sort(hackathonSortFunc)
  }, [
    hackathonList,
    statusFilter,
    formatFilter,
    areaFilter,
    periodFilter,
    tagFilter,
  ])

  const statusButtons: { label: StatusFilter; value: StatusFilter }[] = [
    { label: "すべて", value: "すべて" },
    { label: "開催中", value: "開催中" },
    { label: "開催予定", value: "開催予定" },
    { label: "終了", value: "終了" },
  ]

  return (
    <div className="container mx-auto min-h-screen w-full">
      {/* PC表示用のサイドバー */}
      <div className="flex">
        <aside className="sticky top-16 hidden h-full w-1/3 space-y-5 px-5 lg:block lg:w-1/5">
          <div className="space-y-2">
            <div className="flex items-center gap-5">
              <h3 className="text-lg font-bold">絞り込む</h3>
              <Button
                isIconOnly
                aria-label="clear filter"
                size="sm"
                onClick={clearFilters}
              >
                <RiFilterOffFill className="h-5 w-5 text-gray-400" />
              </Button>
            </div>
            <p className="text-md font-semibold">
              {filteredAndSortedHackathonList.length} / {hackathonList.length}
              件のハッカソン
            </p>
          </div>
          <FilterContent
            formatFilter={formatFilter}
            setFormatFilter={setFormatFilter}
            areaOptions={areaOptions}
            areaFilter={areaFilter}
            setAreaFilter={setAreaFilter}
            periodFilter={periodFilter}
            setPeriodFilter={setPeriodFilter}
            tagOptions={tagOptions}
            tagFilter={tagFilter}
            setTagFilter={setTagFilter}
          />
        </aside>

        <section className="flex w-full flex-1 flex-col gap-5 px-4">
          {/* SP表示用のヘッダー */}
          <div className="sticky top-0 z-10 flex items-center justify-between gap-2 rounded-lg bg-lightBackground px-4 py-2.5 lg:hidden">
            <p className="text-sm font-semibold">
              {filteredAndSortedHackathonList.length} / {hackathonList.length}
              件のハッカソン
            </p>
            <Button
              color="secondary"
              onPress={() => setIsSPFilterModalOpen(true)}
              startContent={<RiFilterFill />}
              className="font-semibold"
            >
              絞り込み
            </Button>
          </div>

          {/* ステータスフィルター（共通） */}
          <div className="flex gap-2 overflow-x-auto">
            {statusButtons.map((button) => (
              <Button
                key={button.value}
                onClick={() => setStatusFilter(button.value)}
                color={statusFilter === button.value ? "primary" : "default"}
                className="flex-shrink-0"
              >
                {button.label}
              </Button>
            ))}
          </div>

          {/* ハッカソン一覧 */}
          <div>
            <div className="flex flex-wrap gap-5">
              {filteredAndSortedHackathonList.map((hackathon) => (
                <HackathonCard
                  key={hackathon.info.id}
                  hackathon={hackathon}
                  organizers={
                    hackathonOrganizerMap.get(hackathon.info.id) ?? []
                  }
                />
              ))}
            </div>
            {filteredAndSortedHackathonList.length === 0 && (
              <p className="text-center text-gray-500">
                該当するハッカソンがありません。
              </p>
            )}
          </div>
        </section>
      </div>

      {/* SP表示用のフィルターモーダル */}
      <Modal
        isOpen={isSPFilterModalOpen}
        onClose={() => setIsSPFilterModalOpen(false)}
        scrollBehavior="inside"
        size="4xl"
        className="lg:hidden"
        placement="bottom"
      >
        <ModalContent>
          <ModalHeader className="flex items-center justify-between">
            <div className="w-full space-y-2">
              <p>絞り込み条件</p>
              <Button
                size="sm"
                variant="bordered"
                onPress={clearFilters}
                startContent={<RiFilterOffFill />}
                className="w-full"
              >
                条件をリセットする
              </Button>
            </div>
          </ModalHeader>
          <ModalBody>
            <FilterContent
              formatFilter={formatFilter}
              setFormatFilter={setFormatFilter}
              areaOptions={areaOptions}
              areaFilter={areaFilter}
              setAreaFilter={setAreaFilter}
              periodFilter={periodFilter}
              setPeriodFilter={setPeriodFilter}
              tagOptions={tagOptions}
              tagFilter={tagFilter}
              setTagFilter={setTagFilter}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onPress={() => setIsSPFilterModalOpen(false)}
              className="w-full"
            >
              条件を適用する ({filteredAndSortedHackathonList.length}件)
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}
