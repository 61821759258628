import { Card, CardBody, CardFooter } from "@nextui-org/card"
import { Image } from "@nextui-org/image"
import clsx from "clsx"
import NextLink from "next/link"
import { FC } from "react"

import { IconTextItem } from "../[hackathonId]/_components/IconTextItem"
import { OrganizerInfo } from "../[hackathonId]/_components/OrganizerInfo"

import { TOrganizer } from "@/api/organizer"
import { TRaidSummary } from "@/api/raid"
import { HackathonTag } from "@/components/Hackathon/Tag"
import { fontMontserrat } from "@/config/fonts"
import { formatHackathonCardDate } from "@/lib/formatHackathonDate"

type Props = {
  hackathon: TRaidSummary
  organizers: TOrganizer[]
}

export const HackathonCard: FC<Props> = ({ hackathon, organizers }) => {
  return (
    <NextLink href={`/hackathon/${hackathon.info.id}`}>
      <Card
        className="text-left hover:cursor-pointer sm:w-[360px]"
        isBlurred
        shadow="none"
        isPressable
      >
        <CardBody className="p-0">
          <div className="relative">
            <Image
              src={hackathon.info.imageUrl}
              alt={hackathon.info.title}
              className="w-full object-cover"
            />
            <div className="absolute inset-0 z-40 bg-gradient-to-br from-black/40 from-0% via-black/0 via-30% to-black/0 to-100%">
              <p
                className={clsx(
                  "p-2 font-mono text-xl font-bold text-white",
                  fontMontserrat.variable,
                )}
                suppressHydrationWarning
              >
                {formatHackathonCardDate(hackathon.info.eventDate.raidStart)}〜
                {formatHackathonCardDate(hackathon.info.eventDate.raidEnd)}
              </p>
            </div>
          </div>
        </CardBody>
        <CardFooter className="flex flex-col items-start gap-2">
          <h3 className="text-lg font-bold">{hackathon.info.title}</h3>
          <IconTextItem
            iconInfo={{ name: "RiMapPinRangeFill", isRiIcon: true }}
            text={hackathon.info.eventDate.kickoff}
            isDate
          />
          <div className="flex gap-2">
            <IconTextItem
              iconInfo={{ name: "RiMapPinLine", isRiIcon: true }}
              text={hackathon.info.venueInfo.venueArea}
            />
            <IconTextItem
              iconInfo={{ name: "RiTrophyLine", isRiIcon: true }}
              text={hackathon.info.prize.total}
            />
          </div>
          <div className="flex flex-wrap gap-2">
            {hackathon.tags.map((tag) => (
              <HackathonTag value={tag.value} key={tag.id} />
            ))}
          </div>
          <IconTextItem
            iconInfo={{ name: "RiTeamFill", isRiIcon: true }}
            text={hackathon.info.playerLimit}
          />
          <OrganizerInfo organizers={organizers} containSnsIcons={false} />
        </CardFooter>
      </Card>
    </NextLink>
  )
}
